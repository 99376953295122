import { parse } from 'query-string'
import { API_ROOM_BOOKINGS, API_SESSIONS, API_TRAINING_ROOMS } from 'constants/api-v2'
import QueryClient from 'services/query'

interface LoaderParams {
  params: {
    id?: string
  }
  request: Request
}

export const loadRoom = (client: QueryClient) => (
  async ({ request }: LoaderParams) => {
    const url = new URL(request.url)

    let room = null
    let date = null

    const dateParam = url.searchParams.get('date')
    date = (!!dateParam && !Number.isNaN(new Date(dateParam))) ? dateParam : null

    const roomParam = url.searchParams.get('room')
    if (!!roomParam && Number.isInteger(parseInt(roomParam, 10))) {
      try {
        room = await client.request(`${API_TRAINING_ROOMS}/${roomParam}`)
      } catch (e: any | unknown) {
        if (!!e.response) throw e.response
      }
    }

    return {
      room,
      date,
    }
  }
)

export const loadBooking = (client: QueryClient) => (
  async ({ params }: LoaderParams) => {
    const { id } = params

    let booking = null

    try {
      booking = await client.request(`${API_ROOM_BOOKINGS}/${id}`)
    } catch (e: any | unknown) {
      if (!!e.response) throw e.response
    }

    return { booking, date: null }
  }
)

export const loadBookingFromSession = (client: QueryClient) => (
  async ({ params, request }: LoaderParams) => {
    const { id } = params
    const url = new URL(request.url)
    const queryParams = parse(url.search)
    const fromSession = queryParams.fromSession === 'true'
    if (fromSession) {
      let booking = null

      try {
        booking = await client.get(`${API_SESSIONS}/${id}/room-booking`)
      } catch (e: any | unknown) {
        if (!!e.response) throw e.response
      }

      return { booking, date: null }
    }
    return { booking: null, date: null }
  }
)
